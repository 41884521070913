import React, { useState,useEffect } from "react"

import { Table } from 'reactstrap'
import { toast } from 'react-toastify'

import { _getBaoHanh } from "../../api/baoHanh"
import ScrollAnimation from 'react-animate-on-scroll'
import moment from 'moment'
import image from '../../assets/image/ImageTraCuu.jpg'

const THoiHanBaoHanh = () => {

  const [serial, setSerial] = useState('')
  const [ttBaoHanh, setTTBaoHanh] = useState(null)
  const [scrollTitle, setScrollTitle] = useState(null)
useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0); // Đưa trang lên đầu ngay lập tức
    }, 0);
  }, []);
  const onClick = () => { 
    setTTBaoHanh(null)
    const body = {
      serial: serial
    }
    if (serial != '') {
      _getBaoHanh(body)
        .then(res => {
          setTTBaoHanh(res)
        })
        .catch(errors => {
          setTTBaoHanh(false)
          // toast.info(errors.response)
        })
    } else {
      toast.info('Bạn cần điền số serial trước')
    }
  }



  return (
    <div className="thoiHanBaoHanh" style={{height:'55vh'}}>
      <div className="container">
        <div className="title-content text-center mb-3">
          <h3 className="text-uppercase fw-bold">Tra cứu thời hạn bảo hành</h3>
          <h6 className="fw-bold">Dịch vụ bảo hành, sửa chữa, chăm sóc tư vấn sản phẩm</h6>
        </div>
        <h6 className="text-center">Vui lòng điền dãy số Series của thiết bị cần bảo hành vào trường nhập dưới đây</h6>
        <div className='mb-2 mt-2 d-flex justify-content-center'>
          <input
            className='form-control w-50 '
            placeholder='Nhập mã số đề nghị bảo hành'
            value={serial}
            onChange={(e) => setSerial(e.target.value.toUpperCase())} 
          />
          <button
            className='giga-kich-hoat-btn p-2'
            disabled={!serial}
            onClick={onClick}>Tra cứu</button>
        </div>
        <div className='row '>
          <div className='col-md-12'>
            { ttBaoHanh == false && (
              <div>
                <h6 className="text-center">Chưa có thông tin kích hoạt bảo hành cho sản phẩm này</h6>
              </div>
            )}
            {
              ttBaoHanh &&
              <div>
                <ScrollAnimation
                  animateIn="fadeInDown"
                  animateOut='fadeInDown' >
                  <h6 className='d-block text-center mt-3'>
                  
                  </h6>
                </ScrollAnimation>
                <fieldset>
                  <div className='col-md-12'>
                    <div className='row'>
                      <div className='col-md-7'>
                        <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Tên sản phẩm:</label>
                          <h6 className="col-md-8">
                          <label className="col-form-label">{ttBaoHanh.TEN_HANG}</label>
                          </h6>
                         
                        </div>
                        <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Số Serial Number:</label>
                          <h6 className="col-md-8">
                          <label className="col-form-label fw-bold">{ttBaoHanh.SERIAL}</label>
                          </h6>
                         
                        </div>
                        
                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Model:</label>
                          <label className="col-form-label col-md-8 fw-bold">{ttBaoHanh.MA_HANG}</label>
                        </div> */}

                        <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Ngày kích hoạt:</label>
                          <h6 className="col-md-8">
                            <label className="col-form-label text-red fw-bold">{(ttBaoHanh.NGAY_KICH_HOAT_BH)}</label>
                          </h6>
                        </div>

                         <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Thời gian bảo hành:</label>
                          <h6 className="col-md-8">
                            <label className="col-form-label">{ttBaoHanh.SO_THANG_BAO_HANH} tháng</label>
                          </h6>
                        </div>

                        <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Hết hạn bảo hành:</label>
                          <h6 className="col-md-8"><label className="col-form-label  text-red fw-bold">{ttBaoHanh.HAN_BAO_HANH}</label></h6>
                        </div>

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Số Serial:</label>
                          <label className="col-form-label col-md-8">{ttBaoHanh.SO_SERIAL}</label>
                        </div> */}

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Ngày tạo:</label>
                          <label className="col-form-label col-md-8">{ttBaoHanh.NGAY_TAO === null ? '' : moment(ttBaoHanh.NGAY_TAO).format('DD/MM/YYYY ')}</label>
                        </div> */}

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4">Trạng thái:</label>
                          <label className="col-form-label col-md-8">{ttBaoHanh.TRANG_THAI}</label>
                        </div> */}

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4 text-nowrap">Dự kiến:</label>
                          <label className="col-form-label fw-bold col-md-8">{ttBaoHanh.NGAY_DU_KIEN_GUI_KH}</label>
                        </div> */}

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4 text-nowrap">Thợ bảo hành:</label>
                          <label className="col-form-label fw-bold col-md-8">{ttBaoHanh.TEN_NGUOI_SUA_CHUA}</label>
                        </div> */}

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4 text-nowrap">Ngày hoàn thành:</label>
                          <label className="col-form-label fw-bold col-md-8">{ttBaoHanh.NGAY_HOAN_THANH}</label>
                        </div> */}

                        {/* <div className="form-group row">
                          <label className="col-form-label text-primary fw-bold col-md-4 text-nowrap">Kết quả:</label>
                          <label className="col-form-label fw-bold col-md-8">{ttBaoHanh.KET_QUA_XU_LY}</label>
                        </div> */}

                      </div>
                      <div className='col-md-5 hidden-xs'>
                        <div className='row'>
                          <div className='col-md-12 col-form-label d-flex  justify-content-center'>
                            <img className='img-product w-100' src={image} style={{ height: 323 }} alt='logo' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>


                {/* <ItemBaoHanh ttBaoHanh={ttBaoHanh}/> */}
              </div>
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default THoiHanBaoHanh