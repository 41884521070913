import React, { useEffect, useState } from "react";

import { Table } from "reactstrap";
import dayjs from 'dayjs';
import { _traCuuBaoHanh } from "../../api/baoHanh";
import { LockOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DatePicker, Pagination, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DoiMatKhau,
  getDanhSachPhieuBaoHanh,
  getDemDanhSachPhieuBaoHanh,
  login,
} from "../../redux/category/action";

import { Modal, Form, Input } from "antd";
import { setLoading } from "../../redux/loading/action";
import moment from "moment";
import YeuCauDetail from "./yeucaudetail";

import { formatCurrency } from "../../helper/formatNumber";
import { convertToDateFormat, formatDate } from "../../helper/convertDate";

const TraCuuTinhTrangBaoHanh = () => {
  const dispatch = useDispatch();
  const thirtyDaysAgo = moment().subtract(30, 'days').format('DD/MM/YYYY');

  const currentDate = moment().format('DD/MM/YYYY');
  const [form] = Form.useForm();

  const [maKH, setMaKH] = useState("");
  const [sotrang, setSoTrang] = useState(1);
  const [sobaomat, setSoBaoMat] = useState("");
  const [startDate, setStartDate] = useState("");
  const [soluong, setSoLuong] = useState(10);
  const [isTracuuDetail, setIsTraCuuDetail] = useState(false);
  const [serial, setSerial] = useState("");
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enddate, setEndDate] = useState("");

  const [sochungtu, setSoChungTu] = useState("");

  const [tinhtrang,setTinhTrang] = useState("Tất cả phiếu");
  const danhsachphieubaohanh = useSelector(
    (store) => store.categories.getDanhSachPhieuBaoHanh
  );
  const demdanhsachphieubaohanh = useSelector(
    (store) => store.categories.getDemDanhSachPhieuBaoHanh
  );
  

  const changepassword = useSelector((store) => store.categories.DoiMatKhau);
  const loginWidthDaily = useSelector((store) => store.categories.login);
  const dateFormatList = "DD/MM/YYYY";

  const Login = async () => {
    const params = {
      tkDangNhap: maKH,
      maso: sobaomat,
    };
    await dispatch(setLoading(true));
    const response = await dispatch(login(params));

    await dispatch(setLoading(false));
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0); // Đưa trang lên đầu ngay lập tức
    }, 0);
  }, []);
  const filterTinhTrang = async(value) => {
    setSoTrang(1);
    setTinhTrang(value)
    
    const params = {
      tkDangNhap: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate == "" ? (thirtyDaysAgo) : startDate,
      denngay: enddate == "" ? (currentDate) : enddate,
      sotrang: sotrang,
      soluong: soluong,
      trangthai: value
    };

    await dispatch(setLoading(true));
    const response = await dispatch(getDemDanhSachPhieuBaoHanh(params));

    if (response?.statuscode == true) {
      await dispatch(getDanhSachPhieuBaoHanh(params));
    }

    await dispatch(setLoading(false));
  } 


  console.log(startDate , enddate)
  const onClick = async () => {
    setSoTrang(1)
    const params = {
      tkDangNhap: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate == "" ? (thirtyDaysAgo) : startDate,
      denngay: enddate == "" ? (currentDate) : enddate,
      sotrang: sotrang,
      soluong: soluong,
      trangthai: "Tất cả phiếu"
    };

    await dispatch(setLoading(true));
    const response = await dispatch(getDemDanhSachPhieuBaoHanh(params));

    if (response?.statuscode == true) {
      await dispatch(getDanhSachPhieuBaoHanh(params));
    }

    await dispatch(setLoading(false));
  };
 
  const onShowSizeChange = async (current, pageSize) => {
    await setSoLuong(pageSize);
    const params = {
      tkDangNhap: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate == "" ? (thirtyDaysAgo) : startDate,
      denngay: enddate == "" ? (currentDate) : enddate,
      sotrang: sotrang,
      soluong: pageSize,
      trangthai: tinhtrang
    };

    await dispatch(setLoading(true));
    await dispatch(getDanhSachPhieuBaoHanh(params));
    await dispatch(getDemDanhSachPhieuBaoHanh(params));
    await dispatch(setLoading(false));
    setSoTrang(current)
  };

  const handlePageChange = async (page, pageSize) => {
    setSoTrang(page);
    const params = {
      tkDangNhap: maKH,
      maso: sobaomat,
      serial: serial,
      tungay: startDate == "" ? (thirtyDaysAgo) : startDate,
      denngay: enddate == "" ? (currentDate) : enddate,
      sotrang: page,
      soluong: pageSize,
      trangthai: tinhtrang
    };

    await dispatch(setLoading(true));

    await dispatch(getDanhSachPhieuBaoHanh(params));

    await dispatch(getDemDanhSachPhieuBaoHanh(params));
    await dispatch(setLoading(false));
  };

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString);
  };
  const navigateToYeuCauDetail = (id) => {
    setIsTraCuuDetail(true);
    setSoChungTu(id);
    // history.push(`/gui-yeu-cau/${id}`);
  };


  const handleOkDetail = () => {
    setIsTraCuuDetail(false);
  };
  const handleCancelDetail = () => {
    setIsTraCuuDetail(false);
  };

 
 
  const disabledDate = (current) => {
    const startDate = thirtyDaysAgo
    const today = currentDate

    return current && (current < startDate || current > today);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    const body = {
      tkDangNhap: maKH,
      maso: values.passwordcurrent,
      masomoi: values.passwordnew,
    };
    if (values.passwordnew != values.passwordrepeat) {
      toast.error("Mật khẩu nhập lại không khớp với mật khẩu mới");
    } else {
      await dispatch(DoiMatKhau(body));
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  

  return (
    <div className="container mt-6" >
      <div className="content h-100 align-middle ">
        <div className="title-content text-center mb-3">
          {loginWidthDaily?.tenkhachhang ? (
            <>
            <div className="col-12" style={{ display: "flex" }} >
              <div className="col-11" style={{ display: "flex", justifyContent: "center" }}>
                <div className="mb-4 mt-2 d-flex justify-content-center col-md-6 welcome-title">
                  <div className="four">
                    <h1 className="title-welcome">
                      <span>Chào mừng</span>{" "}
                      {loginWidthDaily?.tenkhachhang ?? ""}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-1">
              <div className="d-flex justify-content-center">
                <a className="text-gray fw-bold change_password" onClick={showModal}>
                  Đổi mật khẩu
                </a>
              </div>
              </div>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-uppercase fw-bold">
                TRA CỨU TÌNH TRẠNG BẢO HÀNH - SỬA CHỮA
              </h3>
              <h6 className="fw-bold">Dành cho khách hàng đại lý</h6>
            </>
          )}
        </div>
        <div className="login_with_daily">
        <div
          className="row "
          style={{
            marginRight: "20%",
            marginLeft: "20%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!loginWidthDaily?.tenkhachhang && (
            <>
            
              <div className="mb-4 mt-2 d-flex justify-content-center col-md-4 ">
                <input
                  className="form-control w-full"
                  placeholder="Tài khoản khách hàng"
                  required
                  value={maKH}
                  onChange={(e) => setMaKH(e.target.value)}
                />
              </div>
              <div className="mb-4 mt-2 d-flex justify-content-center col-md-4 ">
                <input
                  className="form-control w-full "
                  type="password"
                  required
                  placeholder="Mật khẩu"
                  value={sobaomat}
                  onChange={(e) => setSoBaoMat(e.target.value)}
                />
              </div>
              
            </>
          )}
          {loginWidthDaily?.tenkhachhang ? (
            <>
             
            </>
          ) : (
            <div className="mb-4 mt-2 d-flex justify-content-center col-md-3">
              <button className="giga-kich-hoat-btn" onClick={Login}>
                Đăng nhập
              </button>
            </div>
            
          )}
          </div>
        </div>
        {loginWidthDaily?.tenkhachhang && (
          <>
            <div className="row">
             
              <div className="col-md-12 my-3" style={{ display: 'flex', alignItems: 'center' }}>

                <div
                  className="daily_question col-md-1"
                  style={{ display: "flex", fontWeight: "600" }}
                >
                  Nhập số serial:{" "}
                </div>{" "}
                <input
                  className='form-control mr-2' style={{ width: '30%' }}
                  placeholder='Nhập mã số serial'
                  value={serial} onChange={(e) => setSerial(e.target.value)}
                />
              </div>{" "}
              <br /> <br />
              <div className="col-md-12 " style={{ display: 'flex', alignItems: 'center' }}>

                    <div className="daily_question col-md-1"   style={{ display: "flex", fontWeight: "600" }}>
                      Từ ngày:
                    </div>
                    <div className="col-md-2">
                      <DatePicker
                        onChange={onChangeStartDate}
                        name="startdate"
                        placeholder="Chọn ngày"
                        picker="startDate"
                        format={dateFormatList}
                        disabledDate={disabledDate}
                        defaultValue={dayjs(thirtyDaysAgo, dateFormatList)}
                      />
                    </div>

                  <div
                   className="daily_question col-md-1"   style={{ display: "flex", fontWeight: "600" }}
                  > 
                      Đến ngày: </div>
                    <div className="col-md-2">
                      <DatePicker
                        onChange={onChangeEndDate}
                        name="enddate"
                           placeholder="Chọn ngày"
                        picker="endDate"
                        format={dateFormatList}
                        disabledDate={disabledDate}
                        defaultValue={dayjs(currentDate, dateFormatList)}
                      />
                    </div>
                  
              

                <div className="col-md-2">
                  <button className="giga-kich-hoat-btn " onClick={onClick}>
                    Tìm kiếm
                  </button>
                </div>
              </div>
            </div>
            <br />
            
            <div className="row">
              <div className="col-md-8" style={{ display: "flex" }}>
                <div className={`col-md-2 tab_tracuubaohanh ${tinhtrang === "Tất cả phiếu" ? "active" : ""}`}>
                  <div onClick={() => filterTinhTrang("Tất cả phiếu")}  className={`cursor-pointer`}>
                    Tất cả phiếu(
                    {demdanhsachphieubaohanh?.datas?.TAT_CA_PHIEU ?? 0})
                  </div>
                </div>
                <div className={`col-md-2 tab_tracuubaohanh ${tinhtrang === "Phiếu mới tạo" ? "active" : ""}`}>
                  <div onClick={() => filterTinhTrang("Phiếu mới tạo")}  className={`cursor-pointer `}
                  >
                    Phiếu mới tạo(
                    {demdanhsachphieubaohanh?.datas?.PHIEU_MOI_TAO ?? 0})
                  </div>
                </div>
                <div className={`col-md-2 tab_tracuubaohanh ${tinhtrang === "Đang sửa chữa" ? "active" : ""}`}>
                  <div onClick={() => filterTinhTrang("Đang sửa chữa")}  className={`cursor-pointer`}
                  >
                    Đang sửa chữa(
                    {demdanhsachphieubaohanh?.datas?.DANG_SUA_CHUA ?? 0})
                  </div>
                </div>
                <div className={`col-md-2 tab_tracuubaohanh ${tinhtrang === "Đang chuyển trả" ? "active" : ""}`}>
                  <div onClick={() => filterTinhTrang("Đang chuyển trả")} className={`cursor-pointer`}
                  >
                    Đang chuyển trả(
                    {demdanhsachphieubaohanh?.datas?.DANG_CHUYEN_TRA ?? 0})
                  </div>
                </div>
                <div className={`col-md-2 tab_tracuubaohanh ${tinhtrang === "Hoàn tất" ? "active" : ""}`}>
                  <div onClick={() => filterTinhTrang("Hoàn tất")}  className={`cursor-pointer ${tinhtrang === "Hoàn tất" ? "active" : ""}`}
                  >
                    Hoàn tất({demdanhsachphieubaohanh?.datas?.HOAN_TAT ?? 0})
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <div
                  className="table-responsive"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "150vh",
                    overflow: "auto",
                  }}
                >
                  <Table bordered>
                    <thead>
                      <tr className="">
                        <th className="text-center bg-giga text-white">STT</th>
                        <th className="text-center bg-giga text-white">
                          Ngày tạo yêu cầu
                        </th>
                        <th className="text-center bg-giga text-white">
                          Mã số yêu cầu
                        </th>
                        <th className="text-center bg-giga text-white">
                          Tên sản phẩm
                        </th>
                        <th className="text-center bg-giga text-white">
                          Serial
                        </th>
                        <th className="text-center bg-giga text-white">
                          Phí dịch vụ
                        </th>
                        <th className="text-center bg-giga text-white">
                          Trạng thái
                        </th>
                        <th className="text-center bg-giga text-white">
                          Ghi chú
                        </th>
                        <th className="text-center bg-giga text-white">
                          Ngày cập nhật
                        </th>
                        <th className="text-center bg-giga text-white">
                          Xem phiếu
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!danhsachphieubaohanh?.datas ? (
                        <tr colspan="9">
                          <div> Chưa có dữ liệu</div>
                        </tr>
                      ) : (
                        <>
                            <>
                              {danhsachphieubaohanh?.datas?.map((item, index) => (
                                <tr key={item.id} id="tra-cuu-phieu-bao-hanh-tr">
                                  {/* <th scope="row" className='middle'> {moment(item.thoiGian).format('DD/MM/YYYY HH:mm')}</th> */}
                                  <td
                                    className="middle"
                                    style={{
                                      whiteSpace: "pre-line",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.index}
                                  </td>
                                  <td className="middle">
                                    {item?.NGAY_TAO_YEU_CAU}
                                  </td>
                                  <td
                                    className="middle pointer"
                                    style={{ whiteSpace: "pre-line", cursor: 'pointer' }}

                                  >
                                    <a target="_blank" rel="noreferrer" href={`/tra-cuu-bao-hanh-detail/${item?.SDT_KH}/${item?.SO_CHUNG_TU}`}>   {item?.SO_CHUNG_TU}</a>
                                  </td>
                                  <td
                                    className="middle hover_sanpham"
                                    style={{ whiteSpace: "pre-line", cursor: 'pointer' }}

                                  >
                                    <a target="_blank" rel="noreferrer" href={`/tra-cuu-bao-hanh-detail/${item?.SDT_KH}/${item?.SO_CHUNG_TU}`}>  {item?.TEN_SAN_PHAM} </a>
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line", cursor: 'pointer' }}

                                  >
                                    <a target="_blank" rel="noreferrer" href={`/tra-cuu-bao-hanh-detail/${item?.SDT_KH}/${item?.SO_CHUNG_TU}`}>  {item?.SERIAL} </a>
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {formatCurrency(item?.CHI_PHI_DICH_VU) ?? 0}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.TRANG_THAI}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.GHI_CHU_TOI_KHACH_HANG}
                                  </td>
                                  <td
                                    className="middle"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {item?.NGAY_CHUYEN_TRANG_THAI}
                                  </td>
                                  {item?.VIEW_CHI_TIET == 1 ? (
                                    <td
                                      className="middle"
                                      style={{
                                        whiteSpace: "pre-line",
                                        textAlign: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        navigateToYeuCauDetail(item?.SO_CHUNG_TU)
                                      }
                                    >
                                      <SearchOutlined />
                                    </td>
                                  ) : (
                                    <td className="middle"></td>
                                  )}
                                </tr>
                              ))}
                            </>

                          

                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="pagination mb-5">
                  <div className="so_trang_pagination">
                    <Pagination
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      defaultCurrent={1}
                      onChange={handlePageChange}
                      current={sotrang}
                      total={
                        (tinhtrang === "Tất cả phiếu")
                          ? demdanhsachphieubaohanh?.datas?.TAT_CA_PHIEU
                          : tinhtrang === "Phiếu mới tạo"
                          ? demdanhsachphieubaohanh?.datas?.PHIEU_MOI_TAO
                          : tinhtrang === "Đang sửa chữa"
                          ? demdanhsachphieubaohanh?.datas?.DANG_SUA_CHUA
                          : tinhtrang === "Đang chuyển trả"
                          ? demdanhsachphieubaohanh?.datas?.DANG_CHUYEN_TRA
                          : tinhtrang === "Hoàn tất"
                          ? demdanhsachphieubaohanh?.datas?.HOAN_TAT
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        title="Đổi mật khẩu"
        okText="Đổi mật khẩu"
        open={isModalOpen}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} name="normal_login" className="login-form">
          <label className="mb-2">Vui lòng nhập mật khẩu hiện tại</label>
          <Form.Item
            name="passwordcurrent"
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu hiện tại!" },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              name="passwordcurrent"
              placeholder="Vui lòng nhập mật khẩu hiện tại!"
            />
          </Form.Item>
          <label className="mb-2">Vui lòng nhập mật khẩu mới!</label>
          <Form.Item
            name="passwordnew"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Vui lòng nhập mật khẩu mới!"
            />
          </Form.Item>
          <label className="mb-2">Xác nhận lại mật khẩu mới!</label>
          <Form.Item
            name="passwordrepeat"
            rules={[{ required: true, message: "Xác nhận lại mật khẩu mới!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Xác nhận lại mật khẩu mới!"
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title=""
        open={isTracuuDetail}
        width={3000}
        okText="Xem danh sách tra cứu"
        onOk={handleOkDetail}
        cancelText="Quay về"
        onCancel={handleCancelDetail}
      >
        <YeuCauDetail sochungtu={sochungtu} />
      </Modal>
    </div>
  );
};

export default TraCuuTinhTrangBaoHanh;
